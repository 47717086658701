import { type Settings as SliderSettings } from 'react-slick';

import { type DtoTag } from '@lp-lib/api-service-client/public';

import { makeSlickArrowButtons } from '../../../src/components/common/Slick';
import { type GamePack } from '../../../src/types/game';
import { LoadingGamePackCard } from '../GamePack/GamePackCollection';
import { Slider } from '../SSRCompatible/Slider';

const [ArrowPrev, ArrowNext] = makeSlickArrowButtons({
  base: 'rounded-full border border-secondary bg-lp-black-002 w-12 h-12 md:w-15 md:h-15 absolute top-1/2 transform -translate-y-1/2 z-5 flex items-center justify-center cursor-pointer',
  prev: {
    container: '-left-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
  next: {
    container: '-right-6',
    disabled: 'opacity-25',
    arrow: 'w-7 h-7 fill-current',
  },
});

const GamePackSliderSettings: SliderSettings = {
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 2200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 10000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
      },
    },
  ],
  prevArrow: <ArrowPrev />,
  nextArrow: <ArrowNext />,
};

export function PublicLibraryGamePackList(props: {
  packs: GamePack[];
  renderCard: (pack: GamePack, index: number) => JSX.Element;
}) {
  const { packs, renderCard } = props;

  return (
    <div
      className={`
        w-full pt-5 
        grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 lp-lg:grid-cols-6 gap-y-2.5
      `}
    >
      {packs.map((pack, index) => (
        <div className='w-full px-1' key={pack.id}>
          {renderCard(pack, index)}
        </div>
      ))}
    </div>
  );
}

export function PublicLibraryGamePackListLoading(props: { count: number }) {
  return (
    <div className='w-full pt-5 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 lp-lg:grid-cols-6 gap-y-2.5'>
      {[...Array(props.count)].map((_, i) => (
        <div className='w-full px-1' key={i}>
          <LoadingGamePackCard styles={{ size: 'w-full h-[283px]' }} />
        </div>
      ))}
    </div>
  );
}

export function PublicLibraryGamePackCarousel(props: {
  packs: GamePack[];
  renderCard: (pack: GamePack, index: number) => JSX.Element;
}) {
  const { packs, renderCard } = props;

  return (
    <Slider {...GamePackSliderSettings} className={`w-full`}>
      {packs.map((pack, index) => (
        <div className='w-full pt-5 px-1' key={pack.id}>
          {renderCard(pack, index)}
        </div>
      ))}
    </Slider>
  );
}

export function PublicLibraryGamePackCarouselLoading(props: { count: number }) {
  return (
    <Slider {...GamePackSliderSettings} className={`w-full`}>
      {[...Array(props.count)].map((_, i) => (
        <div className='w-full pt-5 px-1' key={i}>
          <LoadingGamePackCard styles={{ size: 'w-full h-[283px]' }} />
        </div>
      ))}
    </Slider>
  );
}

export function PublicLibraryTagList(props: {
  tags: DtoTag[];
  renderCard: (tag: DtoTag, index: number) => JSX.Element;
}) {
  const { tags, renderCard } = props;

  return (
    <div className='w-full pt-5 grid grid-cols-1 xl:grid-cols-2 lp-lg:grid-cols-3 gap-x-2.5 gap-y-2.5'>
      {tags.map((tag, index) => (
        <div className='w-full' key={tag.id}>
          {renderCard(tag, index)}
        </div>
      ))}
    </div>
  );
}

export function PublicLibraryTagCarousel(props: {
  tags: DtoTag[];
  renderCard: (tag: DtoTag, index: number) => JSX.Element;
}) {
  const { tags, renderCard } = props;

  return (
    <Slider
      infinite={false}
      slidesToShow={3}
      slidesToScroll={3}
      responsive={[
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 2200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ]}
      prevArrow={<ArrowPrev />}
      nextArrow={<ArrowNext />}
    >
      {tags.map((tag, index) => (
        <div key={tag.id} className='pt-5 px-2'>
          {renderCard(tag, index)}
        </div>
      ))}
    </Slider>
  );
}
